import { SpinnerDotted } from "spinners-react";

export default function CacheBusterLoader()
{
    return (
        <div className="preloader">
            <div className="wrapper">
                <SpinnerDotted />
            </div>
        </div>
    );
}