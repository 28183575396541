export const floorPlanData = [
  {
    type: {
      name: "A",
      unit: "Studio",
    },
    level: "1-6",
    sqft: "624",
    bedrooms: "1",
    bathrooms: "1",
    floor: "1",
  },
  {
    type: {
      name: "B",
      unit: "Studio",
    },
    level: "1-6",
    bedrooms: "2",
    bathrooms: "2",
    floor: "2",
    sqft: "518",
  },
  {
    type: {
      name: "C",
      unit: "Studio",
    },
    level: "1-6",
    bedrooms: "1",
    bathrooms: "2",
    floor: "4",
    sqft: "624",
  },
  {
    type: {
      name: "D",
      unit: "Studio",
    },
    level: "1-6",
    bedrooms: "2",
    bathrooms: "1",
    floor: "3",
    sqft: "518",
  },
];
