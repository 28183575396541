import { createContext } from "react";

type GlobalContextType = {
    setNavGallery: (status: boolean) => void;
    navGallery: boolean;
};

export const GlobalAppContext = createContext<GlobalContextType>({
    setNavGallery: () => { },
    navGallery: false,
});