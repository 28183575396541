import { useState, useEffect } from "react";
import CacheBuster from "react-cache-buster";
import packageInfo from "../package.json";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

// context
import { GlobalAppContext } from "context/global-context";

// components
import Preloader from "components/pre-loader";
import CacheBusterLoader from "components/cache-buster-loader";
import Nav from "components/nav";

// pages
import Floorplans from "pages/Floorplans";
import SingleFloorplan from "pages/SingleFloorplan";
import Gallery from "pages/Gallery";
import Location from "pages/Location";
import Overview from "pages/Overview";
import StartScreen from "pages/StartScreen";
import NotFound from "pages/NotFound";

// import splide styles
import "@splidejs/react-splide/css";

// main style theme
import "./styles/theme.scss";

// image data
import ImageData from "PreloadImageData";
import { LoadImage } from "utility/image-preloader";

const NavRouteBlacklist = ["/start-screen"];

function App() {
  // check if in production
  const isProduction = process.env.NODE_ENV === "production";

  // use location
  const location = useLocation();

  // handle nav toggling
  const [showNav, setShowNav] = useState<boolean>(false);
  const [isNavGallery, setIsNavGallery] = useState<boolean>(false);
  const [showPreloader, setShowPreloader] = useState<boolean>(false);

  // fetch the content then disable the preloader here
  useEffect(() => {
    Promise.all(
      ImageData.map(async (image) => {
        const data = (await LoadImage(image)) as string;
        return data;
      })
    )
      .then((data) => {
        setShowPreloader(false);
      })
      .catch((err) => {
        console.error(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!NavRouteBlacklist.includes(location.pathname)) setShowNav(true);
    else setShowNav(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CacheBuster
      currentVersion={packageInfo.version}
      isEnabled={isProduction}
      isVerboseMode={false}
      loadingComponent={<CacheBusterLoader />}
    >
      <main className='App'>
        {/* preloader */}
        {showPreloader && <Preloader />}

        <GlobalAppContext.Provider
          value={{
            setNavGallery: (type: boolean) => setIsNavGallery(type),
            navGallery: isNavGallery,
          }}
        >
          {/* nav */}
          {showNav && <Nav />}

          <AnimatePresence
            mode='wait'
            onExitComplete={() => {
              // re-toggle the nav if necessary
              if (NavRouteBlacklist.includes(location.pathname))
                setShowNav(false);
              else setShowNav(true);
            }}
          >
            <Routes location={location} key={location.pathname}>
              {/* base redirect */}
              <Route path='/' element={<Navigate to='/start-screen' />} />

              {/* floorplans */}
              <Route path='/floorplans' element={<Floorplans />} />

              {/* single floorplan */}
              <Route path='/floorplans/:id' element={<SingleFloorplan />} />

              {/* gallery */}
              <Route path='/gallery' element={<Gallery />} />

              {/* location */}
              <Route path='/location' element={<Location />} />

              {/* overview */}
              <Route path='/overview' element={<Overview />} />

              {/* screen saver */}
              <Route path='/start-screen' element={<StartScreen />} />

              {/* Not Found */}
              <Route path='*' element={<NotFound />} />
            </Routes>
          </AnimatePresence>
        </GlobalAppContext.Provider>
      </main>
    </CacheBuster>
  );
}

export default App;
