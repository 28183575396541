// images

// masterplan
import masterplanImage from 'static/images/masterplan-rendering-converted.webp';

// building renderings
import buildingRendering1 from 'static/images/buidling-renderings/01_more-sky-converted.webp';
import buildingRendering2 from 'static/images/buidling-renderings/02-converted.webp';
import buildingRendering3 from 'static/images/buidling-renderings/03-converted.webp';
import buildingRendering4 from 'static/images/buidling-renderings/04-converted.webp';

// sol renderings
import sol1 from 'static/images/sol-renderings/Sol-Baby Room-converted.webp';
import sol2 from 'static/images/sol-renderings/Sol-Bathroom-converted.webp';
import sol3 from 'static/images/sol-renderings/Sol-Bedroom-converted.webp';
import sol4 from 'static/images/sol-renderings/Sol-Full-converted.webp';
import sol5 from 'static/images/sol-renderings/Sol-Kitchen-converted.webp';
import sol6 from 'static/images/sol-renderings/Sol-Living Room-converted.webp';
import sol7 from 'static/images/sol-renderings/Sol-Office-converted.webp';

// luna renderings
import luna1 from 'static/images/luna-renderings/Luna-Bathroom-converted.webp';
import luna2 from 'static/images/luna-renderings/Luna-Bedroom-converted.webp';
import luna3 from 'static/images/luna-renderings/Luna-Full-converted.webp';
import luna4 from 'static/images/luna-renderings/Luna-Kitchen-converted.webp';
import luna5 from 'static/images/luna-renderings/Luna-Living Room-converted.webp';
import luna6 from 'static/images/luna-renderings/Luna-Office-converted.webp';

// community images

const buildingRenderings = [buildingRendering1, buildingRendering2, buildingRendering3, buildingRendering4];
const solRenderings = [sol1, sol2, sol3, sol4, sol5, sol6, sol7];
const lunaRenderings = [luna1, luna2, luna3, luna4, luna5, luna6];

const ImageData =
    [
        masterplanImage,
        ...buildingRenderings,
        ...solRenderings,
        ...lunaRenderings
    ];

export default ImageData;