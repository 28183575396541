import { useRef } from "react";
import { NavLink, Link } from "react-router-dom";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";

export default function Nav() {
  // refs
  const navBarTextRef = useRef<HTMLParagraphElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const menuTimelineRef = useRef<gsap.core.Timeline>(
    gsap.timeline({
      // old duration 1s
      defaults: { duration: 0.8, ease: "power2.inOut" },
    })
  );

  const { contextSafe } = useGSAP({ scope: menuRef });

  const HandleMenuToggle = contextSafe(() => {
    const toggle = document.querySelector(".menu-toggle") as HTMLButtonElement;
    const navMenu = document.querySelector(".nav-menu") as HTMLElement;
    const navBarSpans = document.querySelectorAll(
      ".hamburger span"
    ) as NodeListOf<HTMLSpanElement>;
    const activeGrowElement = toggle.querySelector(
      ".active-grow-element"
    ) as HTMLDivElement;
    const menuLinkItems = Array.from(
      document.querySelectorAll(".nav-menu .linkout")
    ) as HTMLLinkElement[];
    const subLinksContainer = document.querySelector(
      ".sub-links-container"
    ) as HTMLElement;

    menuTimelineRef.current.clear();

    const isOpen = toggle.classList.contains("-active");

    if (!isOpen) {
      // set active class
      toggle.classList.add("-active");
      menuTimelineRef.current.play();

      gsap.to(navBarSpans[0], {
        x: "0.65rem",
        duration: 0.3,
        ease: "power1.inOut",
        onComplete: () => {
          gsap.to(navBarSpans[0], {
            rotate: 45,
            duration: 0.5,
          });
        },
      });

      gsap.to(navBarSpans[1], {
        x: "-0.65rem",
        duration: 0.3,
        ease: "power1.inOut",
        onComplete: () => {
          gsap.to(navBarSpans[1], {
            rotate: -45,
            duration: 0.5,
          });
        },
      });

      if (navBarTextRef.current) {
        gsap.to(navBarTextRef.current, {
          opacity: 0,
          duration: 0.3,
          ease: "power1.inOut",
          onComplete: () => {
            navBarTextRef.current &&
              (navBarTextRef.current.textContent = "Close");

            gsap.to(navBarTextRef.current, {
              opacity: 1,
              duration: 1,
              ease: "power1.inOut",
            });
          },
        });
      }

      // make toggle not clickable
      gsap.set(toggle, { pointerEvents: "none" });

      // set nav menu pointer events to all
      gsap.set(navMenu, { pointerEvents: "all" });

      menuTimelineRef.current.to(activeGrowElement, {
        scale: 60,
      });

      // animate menu items
      menuTimelineRef.current.fromTo(
        menuLinkItems,
        {
          y: -27,
          autoAlpha: 0,
          pointerEvents: "none",
        },
        {
          y: 0,
          autoAlpha: 1,
          pointerEvents: "all",
          // stagger: 0.3,
          // duration: 0.7,
          stagger: 0.2,
          duration: 0.6,
          ease: "power1.inOut",
        },
        "<50%"
      );

      menuTimelineRef.current.fromTo(
        subLinksContainer,
        {
          yPercent: -25,
          autoAlpha: 0,
          pointerEvents: "none",
        },
        {
          yPercent: 0,
          pointerEvents: "all",
          autoAlpha: 1,
          onComplete: () => {
            gsap.set(toggle, { pointerEvents: "all" });
          },
        },
        "<50%"
      );
      return;
    }
    gsap.to(navBarSpans[0], {
      rotate: 0,
      duration: 0.3,
      ease: "power1.inOut",
      onComplete: () => {
        gsap.to(navBarSpans[0], {
          x: 0,
          duration: 0.5,
        });
      },
    });

    gsap.to(navBarSpans[1], {
      rotate: 0,
      duration: 0.3,
      ease: "power1.inOut",
      onComplete: () => {
        gsap.to(navBarSpans[1], {
          x: 0,
          duration: 0.5,
        });
      },
    });

    if (navBarTextRef.current) {
      gsap.to(navBarTextRef.current, {
        opacity: 0,
        duration: 0.3,
        ease: "power1.inOut",
        onComplete: () => {
          navBarTextRef.current && (navBarTextRef.current.textContent = "Menu");

          gsap.to(navBarTextRef.current, {
            opacity: 1,
            duration: 1,
            ease: "power1.inOut",
          });
        },
      });
    }

    gsap.set(toggle, { pointerEvents: "none" });
    gsap.set(navMenu, { pointerEvents: "none" });

    toggle.classList.remove("-active");

    // animate menu items
    menuTimelineRef.current.to(subLinksContainer, {
      yPercent: -50,
      pointerEvents: "none",
      autoAlpha: 0,
    });
    menuTimelineRef.current.to(
      menuLinkItems,
      {
        y: -25,
        autoAlpha: 0,
        pointerEvents: "none",
        stagger: 0.1,
        reversed: true,
        duration: 0.5,
      },
      "<50%"
    );
    menuTimelineRef.current.to(
      activeGrowElement,
      {
        scale: 0,
      },
      "<25%"
    );
  });

  return (
    <nav className={`main-nav`}>
      <div className='wrapper' onClick={HandleMenuToggle}>
        <div className='menu-toggle'>
          <div className='hamburger'>
            <span></span>
            <span></span>
          </div>

          <p ref={navBarTextRef} className='menu-text'>
            Menu
          </p>
          <div className='active-grow-element'></div>
        </div>
      </div>
      <div ref={menuRef} className='nav-menu'>
        <div className='list-items'>
          {/* overview */}
          <NavLink
            onClick={HandleMenuToggle}
            className={({ isActive, isPending }) =>
              isActive ? "linkout -active" : "linkout"
            }
            to='/overview'
          >
            Overview
          </NavLink>

          {/* floorplans */}
          <NavLink
            onClick={HandleMenuToggle}
            className={({ isActive, isPending }) =>
              isActive ? "linkout -active" : "linkout"
            }
            to='/floorplans'
          >
            Floorplans
          </NavLink>

          {/* Gallery */}
          <NavLink
            onClick={HandleMenuToggle}
            className={({ isActive, isPending }) =>
              isActive ? "linkout -active" : "linkout"
            }
            to='/gallery'
          >
            Gallery
          </NavLink>

          {/* Location */}
          <NavLink
            onClick={HandleMenuToggle}
            className={({ isActive, isPending }) =>
              isActive ? "linkout -active" : "linkout"
            }
            to='/location'
          >
            Location
          </NavLink>
        </div>

        <div className='sub-links-container'>
          {/* disclaimer */}
          <Link className='sub-link' to='/disclaimer'>
            Disclaimer
            <span></span>
          </Link>

          {/* privacy policy */}
          <Link className='sub-link' to='/privacy-policy'>
            Privacy Policy
            <span></span>
          </Link>

          {/* realtor portal */}
          <Link className='sub-link' to='/realtor-portal'>
            Realtor Portal
            <span></span>
          </Link>

          {/* exit */}
          <Link className='sub-link' to='/exit'>
            Exit
          </Link>
        </div>
      </div>
    </nav>
  );
}
