// videos
import video1 from 'static/videos/archviz-fpo-1.mp4';
import video2 from 'static/videos/archviz-fpo-2.mp4';
import video3 from 'static/videos/archviz-fpo-3.mp4';
import video4 from 'static/videos/archviz-fpo-4.webm';

// exteriors
import exterior1 from 'static/images/buidling-renderings/01_more-sky-converted.webp';
import exterior2 from 'static/images/buidling-renderings/02-converted.webp';
import exterior3 from 'static/images/buidling-renderings/03-converted.webp';
import exterior4 from 'static/images/buidling-renderings/04-converted.webp';

// interiors
import sol1 from 'static/images/sol-renderings/Sol-Baby Room-converted.webp';
import sol2 from 'static/images/sol-renderings/Sol-Bathroom-converted.webp';
import sol3 from 'static/images/sol-renderings/Sol-Bedroom-converted.webp';
import sol4 from 'static/images/sol-renderings/Sol-Full-converted.webp';
import sol5 from 'static/images/sol-renderings/Sol-Kitchen-converted.webp';
import sol6 from 'static/images/sol-renderings/Sol-Living Room-converted.webp';
import sol7 from 'static/images/sol-renderings/Sol-Office-converted.webp';
import luna1 from 'static/images/luna-renderings/Luna-Bathroom-converted.webp';
import luna2 from 'static/images/luna-renderings/Luna-Bedroom-converted.webp';
import luna3 from 'static/images/luna-renderings/Luna-Full-converted.webp';
import luna4 from 'static/images/luna-renderings/Luna-Kitchen-converted.webp';
import luna5 from 'static/images/luna-renderings/Luna-Living Room-converted.webp';
import luna6 from 'static/images/luna-renderings/Luna-Office-converted.webp';

// community
import community1 from 'static/images/community/community-fpo-1.jpg';
import community2 from 'static/images/community/community-fpo-2.jpg';
import community3 from 'static/images/community/community-fpo-3.jpg';


export const galleryVideosData = [
    {
        src: video1,
        textContent: 'Lorem ipsum dolor sit amet consectetur adipiscing elit euismod vel curabitur'
    },
    {
        src: video2,
        textContent: 'Lorem ipsum dolor sit amet consectetur adipiscing elit euismod vel curabitur'
    },
    {
        src: video3,
        textContent: 'Lorem ipsum dolor sit amet consectetur adipiscing elit euismod vel curabitur'
    },
    {
        src: video4,
        textContent: 'Lorem ipsum dolor sit amet consectetur adipiscing elit euismod vel curabitur'
    }
];
export const galleryExteriorsData =
    [
        {
            src: exterior1,
            textContent: 'Lorem ipsum dolor sit amet consectetur adipiscing elit euismod vel curabitur'
        },
        {
            src: exterior2,
            textContent: 'Lorem ipsum dolor sit amet consectetur adipiscing elit euismod vel curabitur'
        },
        {
            src: exterior3,
            textContent: 'Lorem ipsum dolor sit amet consectetur adipiscing elit euismod vel curabitur'
        },
        {
            src: exterior4,
            textContent: 'Lorem ipsum dolor sit amet consectetur adipiscing elit euismod vel curabitur'
        }
    ];

export const galleryInteriorsData = [
    {
        src: sol1,
        textContent: 'Lorem ipsum dolor sit amet consectetur adipiscing elit euismod vel curabitur'
    },
    {
        src: sol2,
        textContent: 'Lorem ipsum dolor sit amet consectetur adipiscing elit euismod vel curabitur'
    },
    {
        src: sol3,
        textContent: 'Lorem ipsum dolor sit amet consectetur adipiscing elit euismod vel curabitur'
    },
    {
        src: sol4,
        textContent: 'Lorem ipsum dolor sit amet consectetur adipiscing elit euismod vel curabitur'
    },
    {
        src: sol5,
        textContent: 'Lorem ipsum dolor sit amet consectetur adipiscing elit euismod vel curabitur'
    },
    {
        src: sol6,
        textContent: 'Lorem ipsum dolor sit amet consectetur adipiscing elit euismod vel curabitur'
    },
    {
        src: sol7,
        textContent: 'Lorem ipsum dolor sit amet consectetur adipiscing elit euismod vel curabitur'
    },
    {
        src: luna1,
        textContent: 'Lorem ipsum dolor sit amet consectetur adipiscing elit euismod vel curabitur'
    },
    {
        src: luna2,
        textContent: 'Lorem ipsum dolor sit amet consectetur adipiscing elit euismod vel curabitur'
    },
    {
        src: luna3,
        textContent: 'Lorem ipsum dolor sit amet consectetur adipiscing elit euismod vel curabitur'
    },
    {
        src: luna4,
        textContent: 'Lorem ipsum dolor sit amet consectetur adipiscing elit euismod vel curabitur'
    },
    {
        src: luna5,
        textContent: 'Lorem ipsum dolor sit amet consectetur adipiscing elit euismod vel curabitur'
    },
    {
        src: luna6,
        textContent: 'Lorem ipsum dolor sit amet consectetur adipiscing elit euismod vel curabitur'
    }
];

export const galleryCommunityData = [
    {
        src: community1,
        textContent: 'Lorem ipsum dolor sit amet consectetur adipiscing elit euismod vel curabitur'
    },
    {
        src: community2,
        textContent: 'Lorem ipsum dolor sit amet consectetur adipiscing elit euismod vel curabitur'
    },
    {
        src: community3,
        textContent: 'Lorem ipsum dolor sit amet consectetur adipiscing elit euismod vel curabitur'
    }
];