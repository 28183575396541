import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { floorPlanData } from "data/floorplanData";
import { Filter, FilterOptions } from "pages/Floorplans";
import gsap from "gsap";

interface Props {
  isIdFilter: (filter: Filter) => filter is { type: string; id: string };
  filterOptions: FilterOptions;
}

export default function FloorplanGrid({ isIdFilter, filterOptions }: Props) {
  const [firstLoad, setFirstLoad] = useState(true);

  const gsapGridAnimations = (delay: number): void => {
    const cards = document.querySelectorAll(".floorplan-card");

    gsap.fromTo(
      cards,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 1,
        stagger: 0.1,
        delay: delay,
      }
    );

    if (firstLoad) setFirstLoad(false);
  };

  const filterData = useMemo(() => {
    const hasFilters: {
      key: string;
      value: Filter[];
    }[] = [];

    for (const [key, value] of Object.entries(filterOptions)) {
      if (value.length) {
        hasFilters.push({ key, value });
      }
    }

    const filteredData = floorPlanData.filter((floorplan) => {
      let bedCounter = 0;
      let bathCounter = 0;
      let floorCounter = 0;

      //   loop through filter type
      for (let i = 0; i < hasFilters.length; i++) {
        const { key, value } = hasFilters[i];

        // loop through filter array
        for (let j = 0; j < value.length; j++) {
          const filter = value[j];

          if (isIdFilter(filter)) {
            if (floorplan[key as keyof typeof floorplan] === filter.id) {
              if (key === "bedrooms") {
                bedCounter++;
              } else if (key === "bathrooms") {
                bathCounter++;
              }
            }
          } else {
            const floor = floorplan[key as keyof typeof floorplan];
            const { min, max } = filter.range;

            if (floor && floor >= min && floor <= max) {
              floorCounter++;
            }
          }
        }
      }

      if (floorCounter <= 0) return;

      if (bedCounter <= 0 && bathCounter <= 0 && hasFilters.length > 1) {
        return;
      } else if (
        (bedCounter > 0 && bathCounter <= 0 && hasFilters.length > 2) ||
        (bedCounter <= 0 && bathCounter > 0 && hasFilters.length > 2)
      ) {
        return;
      }

      return floorplan;
    });
    return filteredData;
  }, [filterOptions]);

  useEffect(() => {
    // first load animation delay it to account for framer motion animation
    if (firstLoad) {
      gsapGridAnimations(0.7);
    } else {
      gsapGridAnimations(0);
    }
  }, [filterData]);

  return (
    <div className='floorplans-grid'>
      <div className='row-header'>
        <h2 className='header-type'>Type</h2>
        <h2 className='header-level'>Level</h2>
        <h2 className='header-sqft'>Total SQ. FT.</h2>
      </div>

      {filterData.map((floorplan, index) => {
        const { type, level, sqft } = floorplan;
        return (
          <div className='floorplan-card' key={index}>
            <Link to={`/floorplans/${type.name}`} state={{ floorplan }}>
              <h2 className='type'>{type.name}</h2>
              <p className='unit'>{type.unit}</p>
              <p className='level'>{level}</p>
              <p className='sqft'>{sqft} SQ. FT,</p>
              <p className='view'>View</p>
            </Link>
          </div>
        );
      })}
    </div>
  );
}
