import { Fragment, FunctionComponent } from 'react';
import { motion } from 'framer-motion';

const HandleTransition = (OGComponent: FunctionComponent) =>
{
    return () =>
    {
        return (
            <Fragment>

                <OGComponent />

                <motion.div
                    key="trans-in"
                    className='transitioner-in'
                    initial={ {
                        borderTopLeftRadius: '250rem',
                        borderTopRightRadius: '250rem',
                        scaleY: 0,
                    } }
                    animate={ {
                        borderTopLeftRadius: '250rem',
                        borderTopRightRadius: '250rem',
                        scaleY: 0,
                    } }
                    exit={ {
                        borderTopLeftRadius: '0%',
                        borderTopRightRadius: '0%',
                        scaleY: 1,
                    } }
                    transition={ { ease: [.65, 0, .35, 1], duration: 1.25 } }>
                </motion.div>

                <motion.div
                    key="trans-out"
                    className='transitioner-out'
                    initial={ {
                        borderBottomLeftRadius: '0',
                        borderBottomRightRadius: '0',
                        scaleY: 1,
                    } }
                    animate={ {
                        borderBottomLeftRadius: '250rem',
                        borderBottomRightRadius: '250rem',
                        scaleY: 0,
                    } }
                    exit={ {
                        borderBottomLeftRadius: '250rem',
                        borderBottomRightRadius: '250rem',
                        scaleY: 0,
                    } }
                    transition={ { ease: [.65, 0, .35, 1], duration: 1.25 } }>
                </motion.div>
            </Fragment>
        );
    };
};

export default HandleTransition;
