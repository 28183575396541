import { Link } from "react-router-dom";
import { ReactComponent as LogoText } from "static/svgs/little-mountain-logo-text.svg";

interface HeaderProps {
  paddingBottom?: string;
}

export default function Header({ paddingBottom = "0" }: HeaderProps) {
  return (
    <header
      className='little-mountain-header'
      style={{ paddingBottom: paddingBottom }}
    >
      <Link to='/start-screen'>
        <LogoText />
      </Link>
    </header>
  );
}
