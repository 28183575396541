import { Filter, FilterOptions } from "pages/Floorplans";
import { ReactComponent as FloorplanMain } from "static/svgs/main-floorplan.svg";
import MultiRangeSlider from "components/multi-range-slider";

interface Props {
  filterOptions: FilterOptions;
  setFilterOptions: React.Dispatch<React.SetStateAction<FilterOptions>>;
  isIdFilter: (filter: Filter) => filter is { type: string; id: string };
}

export default function FloorplanFilterControls({
  filterOptions,
  setFilterOptions,
  isIdFilter,
}: Props) {
  const handleFilter = (e: React.MouseEvent<HTMLButtonElement>) => {
    const target = e.target as HTMLButtonElement;
    const filterId = target.id;
    const filterType = target.getAttribute(
      "filter-type"
    ) as keyof FilterOptions;

    if (filterId && filterType) {
      // check if filter already exists
      const filterExists = filterOptions[filterType].find(
        (filter) => isIdFilter(filter) && filter.id === filterId
      );
      // don't add to state if filter is already active
      if (filterExists) {
        setFilterOptions((prev) => {
          const newArr = prev[filterType].filter(
            (filter) => isIdFilter(filter) && filter.id !== filterId
          );
          return { ...prev, [filterType]: [...newArr] };
        });
        target.classList.remove("active");
        return;
      }

      const filters = {
        type: filterType,
        id: filterId,
      };

      setFilterOptions((prev) => {
        const newArr = [...prev[filterType]];
        newArr.push(filters);
        return { ...prev, [filterType]: [...newArr] };
      });

      target.classList.add("active");
    }
  };

  const isRangeFilter = (
    filter: Filter
  ): filter is { type: string; range: { min: string; max: string } } => {
    return (
      (filter as { range: { min: string; max: string } }).range !== undefined
    );
  };

  const handleRangeFilter = (min: number, max: number) => {
    const filterType = "floor";

    if (!min || !max) {
      return;
    }

    const filterExists = filterOptions[filterType].some(
      (filter) =>
        isRangeFilter(filter) &&
        filter.range.min === min.toString() &&
        filter.range.max === max.toString()
    );

    // don't add to state if filter is already active
    if (filterExists) return;

    setFilterOptions((prev) => {
      const newArr = [
        {
          type: filterType,
          range: { min: min.toString(), max: max.toString() },
        },
      ];
      return { ...prev, [filterType]: [...newArr] };
    });
  };

  return (
    <div className='floorplan-filter'>
      <div className='column'>
        <div className='row'>
          <p className='bedrooms-title'>Bedrooms</p>
          <div className='filter-btn-container'>
            <button
              className='filter-btn'
              filter-type='bedrooms'
              id='1'
              onClick={(e) => handleFilter(e)}
            >
              1
            </button>
            <button
              className='filter-btn'
              filter-type='bedrooms'
              id='2'
              onClick={(e) => handleFilter(e)}
            >
              2
            </button>
            <button
              className='filter-btn'
              filter-type='bedrooms'
              id='3'
              onClick={(e) => handleFilter(e)}
            >
              3
            </button>
          </div>
        </div>
        <div className='row'>
          <p className='bedrooms-title'>Bathrooms</p>
          <div className='filter-btn-container'>
            <button
              className='filter-btn'
              filter-type='bathrooms'
              id='1'
              onClick={(e) => handleFilter(e)}
            >
              1
            </button>
            <button
              className='filter-btn'
              filter-type='bathrooms'
              id='2'
              onClick={(e) => handleFilter(e)}
            >
              2
            </button>
          </div>
        </div>
        <div className='row'>
          <MultiRangeSlider
            min={1}
            max={6}
            onChange={({ min, max }: { min: number; max: number }) =>
              handleRangeFilter(min, max)
            }
          />
        </div>
      </div>
      <div className='column'>
        <FloorplanMain />
      </div>
    </div>
  );
}
