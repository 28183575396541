import { useState, useEffect } from "react";
import HandleTransition from "components/transitioner";
import Header from "components/header";
import FloorplanFilterControls from "components/floorplan-filter-controls";
import FloorplanGrid from "components/floorplan-grid";

export type FilterOptions = {
  bedrooms: Filter[];
  bathrooms: Filter[];
  floor: Filter[];
};

export type Filter =
  | { type: string; id: string }
  | { type: string; range: { min: string; max: string } };

function Floorplans() {
  const [filterOptions, setFilterOptions] = useState<FilterOptions>({
    bedrooms: [],
    bathrooms: [],
    floor: [{ type: "floor", range: { min: "1", max: "6" } }],
  });

  const isIdFilter = (
    filter: Filter
  ): filter is { type: string; id: string } => {
    return (filter as { id: string }).id !== undefined;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className='Floorplans'>
      <Header />
      <FloorplanFilterControls
        filterOptions={filterOptions}
        setFilterOptions={setFilterOptions}
        isIdFilter={isIdFilter}
      />
      <div className='black-bar'></div>
      <FloorplanGrid isIdFilter={isIdFilter} filterOptions={filterOptions} />
    </section>
  );
}

export default HandleTransition(Floorplans);
