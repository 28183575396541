import { useRef, useEffect } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import HandleTransition from "components/transitioner";
import Header from "components/header";

// media
import MasterplanRendering from "static/images/masterplan-rendering-converted.webp";

// splide media
import render1 from "static/images/buidling-renderings/01_more-sky-converted.webp";
import render2 from "static/images/buidling-renderings/02-converted.webp";
import render3 from "static/images/buidling-renderings/03-converted.webp";
import render4 from "static/images/buidling-renderings/04-converted.webp";

const splideRenderings = [render1, render2, render3, render4];

function Overview() {
  useEffect(() => {
    window.scrollTo(0, 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // refs
  const splideRef = useRef<Splide>(null);
  return (
    <section className='Overview'>
      <Header paddingBottom={"11.6rem"} />

      <div className='top-container'>
        <p className='main-text'>
          Lorem ipsum dolor sit amet consectetur adipiscing elit leo, inceptos
          netus laoreet dapibus sollicitudin potenti morbi, facilisi odio mattis
          montes cubilia lacus et.
        </p>
      </div>

      <div className='image-container'>
        <div className='black-bar'></div>
        <img src={MasterplanRendering} alt='Masterplan Rendering' />
        <div className='black-bar'></div>
      </div>

      <div className='carousel-container'>
        <div className='row'>
          <div className='col-4'>
            <p className='content'>Lorem ipsum dolor sit amet consectetur.</p>
          </div>

          <div className='col-8'>
            <Splide
              className='overview-carousel'
              ref={splideRef}
              options={{
                type: "loop",
                perPage: 1,
                perMove: 1,
                gap: "4.1rem",
                pagination: false,
                arrows: false,
              }}
            >
              {splideRenderings.map((rendering, index) => {
                return (
                  <SplideSlide key={index} className='carousel-slide'>
                    <img
                      src={rendering}
                      alt={`Building Rendering ${index + 1}`}
                    />
                  </SplideSlide>
                );
              })}
            </Splide>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HandleTransition(Overview);
