import { useEffect } from "react";
import HandleTransition from "components/transitioner";
import { Link } from "react-router-dom";
import { ReactComponent as ArrowRightLong } from 'static/svgs/arrow-right-long.svg';

// media
import video from 'static/videos/screen-saver.webm';

function StartScreen()
{
    useEffect(() =>
    {
        window.scrollTo(0, 0);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <section className="StartScreen">
            <div className="wrapper">
                <div className="content-container">

                    <h1 className="title">Little<br />Mountain</h1>

                    <video
                        className="screen-video"
                        playsInline
                        muted
                        autoPlay
                        loop
                        src={ video }>

                    </video>

                    <Link
                        className="linkout"
                        to="/overview">
                        Touch to start
                        <ArrowRightLong />
                    </Link>
                </div>
            </div>
        </section>
    );
}

export default HandleTransition(StartScreen);