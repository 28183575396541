import { useEffect } from "react";
import { Link } from "react-router-dom";
import HandleTransition from "components/transitioner";

import { ReactComponent as ArrowRight } from 'static/svgs/arrow-right-long.svg';

function NotFound()
{

    useEffect(() =>
    {
        window.scrollTo(0, 0);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <section className="NotFound">

            <div className="wrapper">
                <h1 className="title">
                    404
                </h1>

                <p className="sub-title">
                    Oops, Either this page doesn't exist or I missed something!
                </p>

                <p className="corrupted">The link might be corrupted.</p>

                <p className="removed">or the page may have been removed.</p>

                <Link to="/overview" className="linkout">
                    Go To Overview Page
                    <ArrowRight />
                </Link>
            </div>

        </section>
    );
}

export default HandleTransition(NotFound);