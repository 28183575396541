import { useRef, useEffect, useContext, useState, useReducer } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import HandleTransition from "components/transitioner";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { AnimatePresence, motion } from "framer-motion";

// context
import { GlobalAppContext } from "context/global-context";

// icons
import { ReactComponent as ChevronLeft } from "static/svgs/chevron-left.svg";
import { ReactComponent as ChevronRight } from "static/svgs/chevron-right.svg";

// data
import {
  galleryVideosData,
  galleryExteriorsData,
  galleryInteriorsData,
  galleryCommunityData,
} from "data/galleryData";
import GalleryModal from "components/gallery-modal";

interface IFixedGalleryReducer {
  selectedGallery:
    | "video"
    | "exteriors"
    | "interiors"
    | "community"
    | undefined;
  activeGallery: { src: string; textContent: string }[] | undefined;
  currentIndex: number;
}

function FixedGalleryReducer(
  state: IFixedGalleryReducer,
  action: {
    selectedGallery: IFixedGalleryReducer["selectedGallery"];
    index: number;
  }
): IFixedGalleryReducer {
  switch (action.selectedGallery) {
    case "video":
      return {
        selectedGallery: "video",
        activeGallery: galleryVideosData,
        currentIndex: action.index,
      };
    case "community":
      return {
        selectedGallery: "community",
        activeGallery: galleryCommunityData,
        currentIndex: action.index,
      };
    case "exteriors":
      return {
        selectedGallery: "exteriors",
        activeGallery: galleryExteriorsData,
        currentIndex: action.index,
      };
    case "interiors":
      return {
        selectedGallery: "interiors",
        activeGallery: galleryInteriorsData,
        currentIndex: action.index,
      };
    default:
      return {
        selectedGallery: undefined,
        activeGallery: undefined,
        currentIndex: 0,
      };
  }
}

function Gallery() {
  // context
  const globalContext = useContext(GlobalAppContext);

  // state
  const [fixedGalleryState, fixedGalleryDispatch] = useReducer(
    FixedGalleryReducer,
    {
      selectedGallery: undefined,
      activeGallery: undefined,
      currentIndex: 0,
    }
  );

  // refs
  const splideVideoRef = useRef<Splide>(null);
  const splideExteriorsRef = useRef<Splide>(null);
  const splideInteriorsRef = useRef<Splide>(null);
  const splideCommunityRef = useRef<Splide>(null);

  // fixed gallery ref
  const fixedGalleryContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // known bug: video must be muted on mount
    const videoTags = document.querySelectorAll("video");
    videoTags.forEach((element) => {
      element.muted = true;
    });

    // scroll to top of screen on mount
    window.scrollTo(0, 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() =>
  // {
  // if (!globalContext.navGallery)
  //     fixedGalleryDispatch({ selectedGallery: undefined, index: 0 });

  // }, [globalContext.navGallery]);

  console.log(globalContext, fixedGalleryState);
  return (
    <section className='Gallery'>
      <div className='gallery-container'>
        {/* Video row */}
        <div className='gallery-row'>
          <div className='container-left'>
            <h2 className='title'>Videos</h2>

            <button
              onClick={() => splideVideoRef.current?.go("<")}
              className='splide-btn -prev'
            >
              <ChevronLeft />
            </button>
          </div>

          <div className='container-right'>
            <Splide
              className='gallery-splide'
              ref={splideVideoRef}
              options={{
                type: "loop",
                arrows: false,
                pagination: false,
              }}
            >
              {Object.values(galleryVideosData).map((video, index) => {
                return (
                  <SplideSlide
                    className='gallery-slide'
                    key={`Video Slide ${index}`}
                  >
                    <video
                      src={video.src}
                      autoPlay
                      loop
                      muted
                      playsInline
                      className='video'
                    />
                  </SplideSlide>
                );
              })}
            </Splide>

            <div className='btn-right-container'>
              <button
                onClick={() => splideVideoRef.current?.go(">")}
                className='splide-btn -next'
              >
                <ChevronRight />
              </button>
            </div>
          </div>
        </div>

        {/* Exteriors */}
        <div className='gallery-row'>
          <div className='container-left'>
            <h2 className='title'>Exteriors</h2>

            <button
              onClick={() => splideExteriorsRef.current?.go("<")}
              className='splide-btn -prev'
            >
              <ChevronLeft />
            </button>
          </div>

          <div className='container-right'>
            <Splide
              className='gallery-splide'
              ref={splideExteriorsRef}
              options={{
                type: "loop",
                arrows: false,
                pagination: false,
              }}
            >
              {Object.values(galleryExteriorsData).map((image, index) => {
                return (
                  <SplideSlide
                    className='gallery-slide'
                    onClick={() => {
                      fixedGalleryDispatch({
                        selectedGallery: "exteriors",
                        index,
                      });
                      globalContext.setNavGallery(true);
                    }}
                    key={`Exterior Slide ${index}`}
                  >
                    <img
                      loading='lazy'
                      src={image.src}
                      alt={`Exterior Slide ${index}`}
                    />
                  </SplideSlide>
                );
              })}
            </Splide>

            <div className='btn-right-container'>
              <button
                onClick={() => splideExteriorsRef.current?.go(">")}
                className='splide-btn -next'
              >
                <ChevronRight />
              </button>
            </div>
          </div>
        </div>

        {/* Interiors */}
        <div className='gallery-row'>
          <div className='container-left'>
            <h2 className='title'>Interiors</h2>

            <button
              onClick={() => splideInteriorsRef.current?.go("<")}
              className='splide-btn -prev'
            >
              <ChevronLeft />
            </button>
          </div>

          <div className='container-right'>
            <Splide
              className='gallery-splide'
              ref={splideInteriorsRef}
              options={{
                type: "loop",
                arrows: false,
                pagination: false,
              }}
            >
              {Object.values(galleryInteriorsData).map((image, index) => {
                return (
                  <SplideSlide
                    className='gallery-slide'
                    key={`Interior Slide ${index}`}
                  >
                    <img
                      loading='lazy'
                      src={image.src}
                      alt={`Interior Slide ${index}`}
                    />
                  </SplideSlide>
                );
              })}
            </Splide>

            <div className='btn-right-container'>
              <button
                onClick={() => splideInteriorsRef.current?.go(">")}
                className='splide-btn -next'
              >
                <ChevronRight />
              </button>
            </div>
          </div>
        </div>

        {/* Community */}
        <div className='gallery-row'>
          <div className='container-left'>
            <h2 className='title'>Community</h2>

            <button
              onClick={() => splideCommunityRef.current?.go("<")}
              className='splide-btn -prev'
            >
              <ChevronLeft />
            </button>
          </div>

          <div className='container-right'>
            <Splide
              className='gallery-splide'
              ref={splideCommunityRef}
              options={{
                type: "loop",
                arrows: false,
                pagination: false,
              }}
            >
              {Object.values(galleryCommunityData).map((image, index) => {
                return (
                  <SplideSlide
                    className='gallery-slide'
                    key={`Community Slide ${index}`}
                  >
                    <img
                      loading='lazy'
                      src={image.src}
                      alt={`Community Slide ${index}`}
                    />
                  </SplideSlide>
                );
              })}
            </Splide>

            <div className='btn-right-container'>
              <button
                onClick={() => splideCommunityRef.current?.go(">")}
                className='splide-btn -next'
              >
                <ChevronRight />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        ref={fixedGalleryContainerRef}
        className={`fixed-gallery-container`.concat(
          fixedGalleryState.selectedGallery !== undefined ? "-active" : ""
        )}
      >
        <AnimatePresence>
          {fixedGalleryState.activeGallery &&
            fixedGalleryState.activeGallery.length > 1 && (
              <motion.img
                key={`Highlight Image ${fixedGalleryState.currentIndex}`}
                className='highlight-img'
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ delay: 1, duration: 0.5, ease: "easeInOut" }}
                src={
                  fixedGalleryState.activeGallery[
                    fixedGalleryState.currentIndex
                  ].src
                }
                alt={`${fixedGalleryState.selectedGallery} ${fixedGalleryState.currentIndex} Image`}
              />
            )}

          {fixedGalleryState.activeGallery && (
            <motion.p
              key='Text Content'
              className='textContent'
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ delay: 1, duration: 0.5, ease: "easeInOut" }}
            >
              {
                fixedGalleryState.activeGallery[fixedGalleryState.currentIndex]
                  .textContent
              }
            </motion.p>
          )}

          {fixedGalleryState.activeGallery &&
            fixedGalleryState.activeGallery.length > 1 && (
              <Splide className='fixed-bottom-splide'></Splide>
            )}
        </AnimatePresence>
      </div>

      {/* <GalleryModal /> */}
    </section>
  );
}

export default HandleTransition(Gallery);
