import { useEffect, useState } from "react";
import HandleTransition from "components/transitioner";

function Location()
{
    useEffect(() =>
    {
        window.scrollTo(0, 0);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <section className="Location">
            <h1>Hello from Location</h1>
        </section>
    );
}

export default HandleTransition(Location);