import HandleTransition from "components/transitioner";
import { useLocation } from "react-router-dom";

function SingleFloorplan() {
  const {
    state: { floorplan },
  } = useLocation();
  console.log(floorplan);
  return <h1>Hello from Single Floorplan</h1>;
}

export default HandleTransition(SingleFloorplan);
