export function LoadImage(imageUrl: string)
{
    return new Promise((res, rej) =>
    {
        const newImg = new Image();
        newImg.src = imageUrl;
        newImg.onload = () =>
        {
            setTimeout(() =>
            {
                res(newImg.src);
            }, 500);
        };
        newImg.onerror = (err) => rej(err);
    });
}